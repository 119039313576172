* {
    box-sizing: border-box;
}

body {
    margin-bottom: 25px;
    overflow-x: hidden;
}

.bodytekst, .dropcapbodytekst {
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 25px;
}

.dropcapbodytekst::first-letter {
    padding: 0;
    margin: 0.05rem .2rem -1rem 0;
    font-family: 'Taviraj', serif;

    font-size: 2.5em;
    float: left;
    line-height: 1;
    font-weight: 700;
}

.leadtekst {
    font-family: 'Lato', serif;
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 25px;
}


em {
    font-family: 'Lato', serif;
    font-weight: 300;
    font-style: italic;
    font-size: 1em;
    padding-bottom: 5px;
}

h1, h2, h3, h4, h5 {
    font-family: 'Work Sans', sans-serif;
}

h3 {
    border-bottom: 1px grey solid;
    margin-bottom: 2px;
    padding-bottom: 0;
}

@media only screen and (min-width: 901px) {
    .Incident {
        margin-left: 100px;
        margin-right: 100px;
    }
}

@media only screen and (max-width: 900px) {
    .Incident {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.Artikel, .Bronnen, .Tweets, .YouTube, .KaartContainer, .Galerij,.ReactieFormulier,.Reactie {
    clear: both;
}

.Incident {
    margin-top: 20px;
}

.KaartContainer {
    height: auto;
    margin: 0 0 0 0;
}

.Index {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: stretch;
    align-content: flex-start;
    min-width: 80vw;
    left: 10vw;
}

.IndexBlok {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(135deg, white, lightgray);
}

.OverzichtskaartBlok {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
    margin: 10px;
}

@media only screen and (min-width: 801px) {
    .IndexBlok, .OverzichtskaartBlok {
        flex-basis: 35vw;
    }

    .NavBarText {
        display: inline;
        font-size: 0.9em;
    }

}

@media only screen and (max-width: 800px) {
    .IndexBlok, .OverzichtskaartBlok {
        flex-basis: 80vw;
        margin: 10px 0 10px 0;
    }

    .NavBarText {
        display: none;
    }
}

.NavBar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    margin: 0;
    padding: 5px;
    background-color: black;
    color: white;
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 1em;
    display: block;
    height: 30px;
    z-index: 500;
}

.NavigatieSignIn {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (min-width: 801px) {
    .YouTubeInside {
        width: 50vw;
    }
}

@media only screen and (max-width: 800px) {
    .YouTubeInside {
        width: 80vw;
    }
}

.SearchBox {
    float: right;
    margin-right: 20px;
}

.SearchInput {
    background-color: black;
    color: white;
    border: 2px white solid;
    margin-right: 5px;
    padding: 1px;
    font-size: 0.9em;
}

.SearchButton {
    cursor: pointer;
    color: white;
}

.SearchButtonDisabled {
    color: gray;
}

.vorigeLink {
    float: left;
    text-decoration: none;
}

.volgendeLink {
    float: right;
    text-decoration: none;
}

.IncidentTabel {
    width: 80vw;
}

.IncidentTabel td {
    padding: 3px;
}

.Titelkaart {
    width: 100vw;
    height: 220px;
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    left: 0;
    overflow: hidden;
    position: relative;
}

.TitelkaartAchtergrond {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    animation: zoomCover 3s 1s ease-in-out 1 forwards;
    z-index: -2;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    background-color: dimgray;
}

.TitelkaartAchtergrondZwartwit {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

@keyframes zoomCover {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

.TitelkaartTitel {
    width: 80vw;
    font-size: 2em;
    font-weight: 700;
    color: white;
    text-shadow: 2px 2px 5px black;
    white-space: nowrap;
    z-index: 1;
    top: 20px;
    right: 25px;
    text-align: right;
    padding-right: 5px;
    clear: both;
    position: absolute;
}

.TitelkaartTitelOverflow {
    animation-name: bounce;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.TitelkaartInfo {
    font-size: 0.8em;
    font-weight: 400;
    color: white;
    text-shadow: 2px 2px 3px black;
    z-index: 1;
    bottom: 20px;
    right: 25px;
    padding-right: 5px;
    clear: both;
    position: absolute;
}

.TitelkaartInfo p {
    text-align: right;
    margin: 0.3em 0 0 0;
    padding: 0;
}

@keyframes bounce {
    0% {
        width:75%;
    }
    30% {
        width: 200%;
    }
    50% {
        width: 200%;
    }
    80% {
        width: 75%;
    }
}

.Voorpagina {
    width: 100vw;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.Inleiding {
    width: 100vw;
    position: absolute;
}

.VoorpaginaBlokken {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: stretch;
    align-content: flex-start;
    min-width: 80vw;
    left: 10vw;
}

.VoorpaginaBlok {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
    margin: 10px;
    background-image: linear-gradient(135deg, white, lightgray);
}

@media only screen and (min-width: 801px) {
    .VoorpaginaBlok, .OverzichtskaartBlok {
        flex-basis: 35vw;
    }
}

@media only screen and (max-width: 800px) {
    .VoorpaginaBlok, .OverzichtskaartBlok {
        flex-basis: 80vw;
        margin: 10px 0 10px 0;
    }
}

.SearchBox {
    float: right;
    margin-right: 20px;
}

.SearchInput {
    background-color: black;
    color: white;
    border: 2px white solid;
    margin-right: 5px;
    padding: 1px;
    font-size: 0.9em;
}

.SearchButton {
    cursor: pointer;
    color: white;
}

.SearchButtonDisabled {
    color: gray;
}

.IncidentTabel {
    width: 80vw;
}

.IncidentTabel td {
    padding: 3px;
}

.KaartBox {
    border: 1px solid darkgray;
    padding: 5px;
    width: auto;
    margin: 0;
}

.LijstKaartBox {
    border: 1px solid darkgray;
    padding: 5px;
    width: 90vw;
    height: auto;
    margin: 0;
}

.Kaart {
    width: 100%;
    height: 30vw;
    border: 1px solid darkgray;
    padding: 5px;
    margin: 0;
    position: relative;
    display: block;
}

.Overzichtskaart {
    width: 100%;
    border: 1px solid darkgray;
    padding: 5px;
    position: relative;
    display: block;
    margin-bottom: 25px;
    height: 50vw;
}


.KaartLegenda {
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 0.8em;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
    color: red;
    font-size: 3em;
}

.marker-cluster {
    background-color: lightsteelblue;
}

.marker-cluster-small div {
    background-color: lightskyblue;
    color: whitesmoke;
}

.marker-cluster-medium div {
    background-color: lightskyblue;
    color: whitesmoke;
}

.marker-cluster-large div {
    background-color: lightskyblue;
    color: whitesmoke;
}

.marker-cluster-large-xl div {
    background-color: lightskyblue;
    color: whitesmoke;
}

.fotoContainerLinks, .fotoContainerRechts, .fotoContainerVol {
    border: 1px solid darkgray;
    font-family: 'Lato', serif;
    font-weight: 300;
    font-size: 0.8em;
}

.fotoContainerTitel {
    font-weight: 700;
}

.fotoContainerLinks, .fotoContainerRechts {
    max-width: 30vw;
    height: auto;
    padding: 5px;
}

.fotoContainerRechts {
    float: right;
    margin: 5px 0 5px 10px;
}

.fotoContainerLinks {
    float: left;
    margin: 5px 10px 5px 0;
}

.fotoContainerVol {
    max-width: 80vw;
    height: auto;
    margin: 5px 10vw 5px 10vw;
    padding: 5px;
}

.foto {
    width: 100%;
    height: auto;
    cursor: zoom-in;
    z-index: 0;
}

.fotoContainerThumb {
    margin: 5px;
    z-index: 0;
    border: 1px solid darkgray;
    display: inline-flex;
}

.ShowFotoOverlay {
    z-index: 1000;
    width: 98%;
    height: 98%;
    margin: 0;
    padding: 1% 1%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    top: 1vh;
    left: 1vw;
    position: fixed;
    cursor: pointer;
    display: block;
    border-radius: 10px;
}

.ShowFotoOverlayTekst {
    font-size: 0.8em;
    font-weight: 400;
    color: white;
    text-shadow: 2px 2px 3px black;
    z-index: 1;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: default;
}

.ShowFotoOverlayTekst a:link {
    color: white !important;
}

.ShowFotoOverlayTekst a:visited {
    color: white !important;
}

.ShowFotoOverlayTekst a:hover {
    color: white !important;
}

.ShowFotoOverlayTekst a:active {
    color: white !important;
}

.ReactieBoxDisabled {
    background-color: darkgrey;

}
.ReactieBox {
    background-color: white;
}

.ReactieBox,.ReactieBoxDisabled {
    color: black;
    border: 2px black dotted;
    margin-right: 5px;
    padding: 5px;
    font-size: 1em;
    font-family: 'Lato', serif;
    font-weight: 400;
    width: 100%;
}

.ReactieButton {
    background-color: darkgreen;
    color: white;
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 1em;
}

.ReactieFormulier {
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 1em;
}

.Reactie {
    border-bottom: 1px grey solid;
    margin-bottom: 2px;
    padding-bottom: 0;

}